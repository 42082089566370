import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'

const PetsPost = ({ data }) => {
  const image = getImage(data.mdx.frontmatter.hero_image) 
  return (

    <Layout pageTitle={data.mdx.frontmatter.title}>
      <div className="relative">
        <div className="relative md:absolute md:top-2 md:right-10 bg-white rounded-lg md:my-10 z-10 md:p-8 w-full md:w-2/5 md:shadow-lg">
          <p className="mb-2 text-lg leading-none font-medium font-bold pb-5 ">
            {data.mdx.frontmatter.title}
          </p>
          <p className="mb-2 text-sm text-gray-700 pb-5">
            <MDXRenderer>
              {data.mdx.body}
            </MDXRenderer>
          </p>
          <a href="/contact" className=" bg-primary text-white font-light py-2 px-4 rounded flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
            </svg>
            <span className='ml-3'>
              Złóż zlecenie na szycie
            </span>
          </a>
        </div>
        <GatsbyImage
          className="rounded-lg mt-10 md:mt-0 md:w-2/3 productPicture" 
          image={image}
          alt={data.mdx.frontmatter.hero_image_alt}
        />

      </div>


    </Layout>
  )
}
export const query = graphql`
      query PetsQuery($id: String) {
        mdx(id: {eq: $id}) {
        frontmatter {
        title
        description
        hero_image_alt
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }

      }
      body
    }
  }
      `

export default PetsPost